<template>
  <div>
   
    <template>
      <div class="text-center dailog" @click="hideall">
        <div class="modal-content">
        
    <v-card>
                    <v-card-text>
                      <!-- {{ $t("scama.areyou") }} -->
                      <strong class="red--text">{{ data.namelink }}</strong>
                    </v-card-text>
                    <div class="pa-3" v-if="data.link1">
                      <v-row>
                          <v-col cols="12" sm="4" md="4">
                              {{$t('scama.s1')}} <strong>({{data.linlang1}})</strong>
                          </v-col>
                           <!-- <v-col cols="12" sm="4" md="4">
                              <v-btn color="gold1" outlined @click="cp(`${data.link1}${idus}`)">
                                  <v-icon>mdi-content-copy</v-icon>
                                  {{$t('scama.cp')}}
                              </v-btn>
                          </v-col> -->
                           <v-col cols="12" sm="2" md="1">
                             <a :href="`${data.link1}${idus}`" target="_blank" class="ma-2">
                              <v-btn color="gold5" outlined rounded x-small>
                                  <v-icon>mdi-link</v-icon>
                                  <!-- {{$t('scama.gl')}} --> en
                              </v-btn></a>
                               <a :href="`${data.link1}${idus}`" target="_blank" class="ma-2">
                              <v-btn color="gold5" outlined rounded x-small>
                                  <v-icon>mdi-link</v-icon>
                                  <!-- {{$t('scama.gl')}} --> en
                              </v-btn></a>
                               <a :href="`${data.link1}${idus}`" target="_blank" class="ma-2">
                              <v-btn color="gold5" outlined rounded x-small>
                                  <v-icon>mdi-link</v-icon>
                                  <!-- {{$t('scama.gl')}} --> en
                              </v-btn></a>
                               <a :href="`${data.link1}${idus}`" target="_blank" class="ma-2">
                              <v-btn color="gold5" outlined rounded x-small>
                                  <v-icon>mdi-link</v-icon>
                                  <!-- {{$t('scama.gl')}} --> en
                              </v-btn></a>
                          </v-col>
                          
                      </v-row>
                    </div>
                    <v-divider></v-divider>
                    <div class="pa-3" v-if="data.link2">
                       <v-row>
                          <v-col cols="12" sm="4" md="4">
                            {{$t('scama.s2')}}<strong>({{data.langlink2}})</strong>
                          </v-col>
                           <v-col cols="12" sm="4" md="4">
                              <v-btn color="gold1" outlined @click="cp(`${data.link2}${idus}`)">
                                  <v-icon>mdi-content-copy</v-icon>
                                  {{$t('scama.cp')}}
                              </v-btn>
                          </v-col>
                           <v-col cols="12" sm="5" md="4">
                             <a :href="`${data.link2}${idus}`" target="_blank">
                              <v-btn color="gold5" outlined rounded>
                                  <v-icon>mdi-link</v-icon>
                                  {{$t('scama.gl')}}
                              </v-btn></a>
                          </v-col>
                      </v-row>
                    </div>
                    <v-divider></v-divider>
                    <div class="pa-3" v-if="data.link3">
                       <v-row>
                          <v-col cols="12" sm="4" md="4">
                             {{$t('scama.s3')}}<strong>({{data.langlink3}})</strong>
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                              <v-btn color="gold1" outlined @click="cp(`${data.link3}${idus}`)">
                                  <v-icon>mdi-content-copy</v-icon>
                                  {{$t('scama.cp')}}
                              </v-btn>
                          </v-col>
                           <v-col cols="12" sm="5" md="4">
                             <a :href="`${data.link3}${idus}`" target="_blank">
                              <v-btn color="gold5" outlined rounded>
                                  <v-icon>mdi-link</v-icon>
                                  {{$t('scama.gl')}}
                              </v-btn></a>
                          </v-col>
                      </v-row>
                    </div>
                    <v-divider></v-divider>
                    <!-- <div class="pa-3" v-if="data.link4">
                       <v-row>
                          <v-col cols="12" sm="4" md="4">
                              {{$t('scama.s4')}}<strong>({{data.langlink4}})</strong>
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                              <v-btn color="gold1" outlined @click="cp(`${data.link4}${idus}`)">
                                  <v-icon>mdi-content-copy</v-icon>
                                  {{$t('scama.cp')}}
                              </v-btn>
                          </v-col>
                           <v-col cols="12" sm="5" md="4">
                             <a :href="`${data.link4}${idus}`" target="_blank">
                              <v-btn color="gold5" outlined rounded>
                                  <v-icon>mdi-link</v-icon>
                                  {{$t('scama.gl')}}
                              </v-btn></a>
                          </v-col>
                      </v-row>
                    </div>
                    <v-divider></v-divider> -->

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="$emit('close')">
                        cancel
                      </v-btn>
                      
                    </v-card-actions>
                  </v-card>
         
        </div>
      </div>
      
    </template>
    <v-snackbar
      v-model="snackbar"
      color="#22a56a"
      class="text-weight-bold"
      timeout="1500"
      :width="20"
    >
      <div class="text-center">
        {{ $t("scama.copy") }}
        <v-icon right size="18">mdi-clipboard-check</v-icon>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: ["data","itemsss"],
  data() {
    return {
      dialog: true,
      isLoading: false,
      snackbar:false,
      idus: `&id=${this.$store.getters.getUserId}`
    };
  },
  methods: {
    

  },
};
</script>
<style scoped>
.dailog {
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.modal-content {
  margin: 5% auto; /* 15% from the top and centered */
  border: 1px solid #888;
  width: 60%; /* Could be more or less, depending on screen size */
}
strong{
    font-size-adjust: 11;
    color: crimson;
}
@media (max-width: 800px) {
  .modal-content {
    width: 80%;
  }
  .dailog {
    top: 50px;
  }
}
</style>